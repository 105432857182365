<template>
  <section v-if="appConfig.VueSettingsPreRun.CartLayout === 'Entwash'">
    <cart-entwash-one-stage v-if="appConfig.RestaurantSettingsPreRun.OneStageCart" />
    <cart-entwash-two-stage-first v-else />
  </section>
  <section v-else-if="appConfig.VueSettingsPreRun.CartLayout === 'Rhudaur'">
    <cart-rhudaur-second-step-page />
  </section>
  <cart-common-page />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
