<i18n>
ru:
  itemName: '{item}, {num} шт'
  notAvailable: Недоступно
ua:
  itemName: '{item}, {num} шт'
  notAvailable: Недоступно
us:
  itemName: '{item}, {num} item(s)'
  notAvailable: Not available
</i18n>

<template>
  <ul class="v-d-flex v-flex-column v-second-step-items">
    <li
      class="v-second-step-item"
      v-for="item in items.filter((itemFilter) => !itemFilter.Removed)"
      :key="`cart-item-${item.ID}`"
    >
      <div class="v-d-flex v-flex-row-no-wrap v-justify-content-between">
        <i18n-t
          class="v-typography-text-3"
          tag="span"
          keypath="secondStepItems.itemName"
          scope="global"
        >
          <template #item>
            {{ item.Name }}
          </template>
          <template #num>
            {{ item.RealCount }}
          </template>
        </i18n-t>
        <span
          v-if="
            item.Forbidden ||
            !item.Active ||
            (!item.AllowPickup && clientStore.selfService) ||
            (!item.AllowCourier && clientStore.courierDelivery) ||
            (!item.AllowInHall && clientStore.inHall)
          "
          class="v-typography-text-4 v-color-mono-800 v-mb-sm"
          v-html="translate('secondStepItems.notAvailable')"
        />
        <cart-rhudaur-content-item-price
          v-else
          calc-with-count
          price-class-name="v-typography-text-4"
          :product="item"
        />
      </div>
      <div
        v-if="item.SelectedOption"
        class="v-typography-text-5 v-color-mono-400"
        v-html="
          sanitize(`${item.SelectedOption.Name} ${item.SelectedOption.Description}`)
        "
      />
      <common-quantity-measure
        class="v-typography-text-5 v-color-mono-400"
        v-else-if="
          item.Measure !== ProductMeasureType.Undefined && item.Quantity > 0
        "
        :measure="item.Measure"
        :quantity="item.Count"
      />
      <common-quantity-measure
        class="v-typography-text-5 v-color-mono-400"
        v-else-if="item.Weight > 0"
        :quantity="item.Weight"
      />
      <div
        class="v-color-mono-600"
        v-html="sanitize(item.CustomTextForSaleInfoInCart)"
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { ProductMeasureType } from '~api/consts'

const clientStore = useClientStore()

const { items } = useCart()
const { sanitize, translate } = useI18nSanitized()
</script>

<style lang="scss">
.v-second-step-items {
  margin: 0;
  padding: 0;
  gap: 0.57rem;

  .v-second-step-item {
    list-style: none;
  }
}
</style>
